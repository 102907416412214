<template>
  <div class="head">
    <div
      class="head_banner"
      :class="bannerbackname.IMAGEURL ? 'transparent' : 'defalutback'"
    >
      <div v-show="bannerbackname.IMAGEURL" class="bannerChina">
        <img :src="baseBannerUrl + bannerbackname.IMAGEURL" />
      </div>
      <div
        class="bannerInner"
        :class="bannerbackname.IMAGEURL ? 'transparent' : 'defalutback'"
      >
        <div class="headbar">
          <div class="headSearch">
            <div class="tabSearch">
              <div
                class="litab cleft"
                :class="currents == v.id ? 'lihover' : ''"
                v-for="(v, k) in searchTit"
                :key="k"
                @click="getTabs(v)"
              >
                {{ v.name }}
              </div>
            </div>
            <div class="tabText">
              <div class="inputSearch cleft">
                <a-popover
                  :visible="recVisible"
                  trigger="focus"
                  placement="bottomLeft"
                >
                  <input
                    type="text"
                    v-show="currents == 1"
                    @click="getInput($event)"
                    @input="getRecommendIndicator"
                    v-model="selectSreenSearch"
                    :placeholder="nameinput"
                    @keyup="getInput($event)"
                  />
                  <div slot="content" class="search-rec">
                    <a-menu>
                      <a-menu-item
                        v-for="(item, key) in recList"
                        :key="key"
                        @click="selectKeyword(item.indicatorName)"
                        >{{ item.indicatorName }}</a-menu-item
                      >
                    </a-menu>
                  </div>
                </a-popover>
                <input
                  type="text"
                  v-show="currents == 2"
                  @click="getInput2($event)"
                  v-model="selectSreenSearch2"
                  :placeholder="nameinput2"
                  @keyup.enter="getInput2($event)"
                />
              </div>
              <div class="btnSearch cright" @click="getSearchBtn(currents)">
                <i class="wgicon search"></i>
              </div>
            </div>
            <div class="tabFont" v-show="currents == 1">
              <span class="title cleft">热搜词：</span>
              <span
                class="lis cleft"
                v-for="(v, k) in searchList"
                :key="k"
                @click="getSearchList(currents, v)"
                >{{ v.name }}</span
              >
            </div>
            <div class="tabFont" v-show="currents == 2">
              <span class="title cleft">热搜词：</span>
              <span
                class="lis cleft"
                v-for="(v, k) in searchList2"
                :key="k"
                @click="getSearchList(currents, v)"
                >{{ v.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import axios from "axios";
import { config } from "../../until/common.js";
export default {
  name: "Search",
  props: ["dataEPSobj", "bannerback"],
  data() {
    return {
      recVisible: false,
      recList: [],
      baseBannerUrl: config.baseBannerUrl,
      bannerbackname: {},
      dataEPSobjname: {},
      currents: 1,
      searchTit: [
        { id: 1, name: "时间序列数据" },
        { id: 2, name: "统计表格数据" },
      ],
      selectSreenSearch: "",
      nameinput: "", //
      selectSreenSearch2: "",
      nameinput2: "100w+统计表格数据",
      //（时间序列）热搜词10个，由于宽度有限，暂时展示5个
      searchList: [
        { id: 1, name: "GDP" },
        { id: 2, name: "城镇新增就业人数" },
        { id: 3, name: "城镇调查失业率" },
        { id: 4, name: "CPI" },
        { id: 5, name: "粮食产量" },
        { id: 6, name: "单位GDP能耗" },
      ],
      //（统计报表）热搜词10个，由于宽度有限，暂时展示5个
      searchList2: [
        { id: 1, name: "人口数及构成" },
        { id: 2, name: "人均收入" },
        { id: 3, name: "个人消费贷款" },
        { id: 4, name: "旅游业情况" },
        { id: 5, name: "存贷款利率" },
        { id: 6, name: "教育经费" },
      ],
    };
  },
  methods: {
    //指标推荐接口
    getRecommendIndicator:_.debounce(function() {
      axios({
        method: "post",
        url: "search/recommendIndicator.do?keyword=" + this.selectSreenSearch,
      }).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.hits.length > 0) {
            this.recVisible = true;
            this.recList = res.data.data.hits.slice(0,10);
          }
        }
      });
    },1000),
    //选择推荐词
    selectKeyword(item) {
      this.selectSreenSearch = item;
      this.recVisible = false;
      this.recList = [];
    },
    hideRec() {
       this.recVisible = false;
       this.recList = [];
    },
    getTabs(v) {
      this.currents = v.id;
      if (v.id == 1) {
        this.nameinput =
          this.dataEPSobjname.TOTAL_NUM + "亿+中国及全球时间序列数据";
      } else {
        this.nameinput2 = "100w+统计表格数据";
      }
    },
    getInput(event) {
      const code = event.keyCode? event.keyCode: event.which? event.which: event.charCode;
      if (code == 13) { 
        // window.open("http://olap.epsnet.com.cn/auth/search.html?cubeScope=0&keywords=" + this.selectSreenSearch, "_blank");
        // window.open("/#/Timeseries/" + this.selectSreenSearch, "_blank");
          window.open('http://olap.epsnet.com.cn/#/cross/result?keywords=' + encodeURI(this.selectSreenSearch) ,'_blank');
        this.selectSreenSearch = "";
        this.hideRec()
        this.nameinput =
          this.dataEPSobjname.TOTAL_NUM + "亿+中国及全球时间序列数据";
      }
    },
    getInput2(event) {
      //this.nameinput2 = '';
      const code = event.keyCode
        ? event.keyCode
        : event.which
        ? event.which
        : event.charCode;
      if (code == 13) {
        window.open("/#/Reportform/" + this.selectSreenSearch2, "_blank");
        this.selectSreenSearch2 = "";
        this.nameinput2 = "100w+统计表格数据";
      }
    },
    getSearchBtn(currents) {
      if (currents == 1) {
        if (this.selectSreenSearch != "") {
          // window.open("/#/Timeseries/" + this.selectSreenSearch, "_blank");
          window.open('http://olap.epsnet.com.cn/#/cross/result?keywords='+ encodeURI(this.selectSreenSearch),'_blank');
          this.selectSreenSearch = "";
          this.nameinput =
            this.dataEPSobjname.TOTAL_NUM + "亿+中国及全球时间序列数据";
        } else {
          alert("请输入搜索词");
        }
      } else {
        if (this.selectSreenSearch2 != "") {
          window.open("/#/Reportform/" + encodeURI(this.selectSreenSearch2), "_blank");
          this.selectSreenSearch2 = "";
          this.nameinput2 = "100w+统计表格数据";
        } else {
          alert("请输入搜索词");
        }
      }
    },
    getSearchList(curIndex, v) {
      if (curIndex == 1) {
        this.selectSreenSearch = v.name;
        // window.open("/#/Timeseries/" + this.selectSreenSearch, "_blank");
        window.open('http://olap.epsnet.com.cn/#/cross/result?keywords='+this.selectSreenSearch,'_blank');
        this.selectSreenSearch = "";
        this.nameinput =
          this.dataEPSobjname.TOTAL_NUM + "亿+中国及全球时间序列数据";
      } else {
        this.selectSreenSearch2 = v.name;
        window.open("/#/Reportform/" + this.selectSreenSearch2, "_blank");
        this.selectSreenSearch2 = "";
        this.nameinput2 = "100w+统计表格数据";
      }
    },
  },
  watch: {
    dataEPSobj(newVal) {
      this.dataEPSobjname = newVal;
      if (newVal) {
        this.nameinput = newVal.TOTAL_NUM + "亿+中国及全球时间序列数据";
      }
    },
    bannerback(newVal) {
      if (newVal && newVal.IMAGEURL) {
        this.bannerbackname = newVal;
      }
    },
  },
  mounted(){
    document.addEventListener('click',this.hideRec);
  },
  beforeDestroy() {
        document.removeEventListener('click',this.hideRec);
  }
};
</script>
<style lang="scss">
.search-rec {
  width: 820px;
  .ant-menu-vertical {
    border-right: none;
  }
}
</style>


